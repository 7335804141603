<template>
    <el-container style="height:100%">
        <el-aside width="400px" style="padding:5px">
            <el-input placeholder="输入关键字进行过滤" size="small" v-model="filterText"></el-input>
            <el-tree ref="tree"
                     :data="treeData"
                     :props="defaultProps"
                     node-key="id"
                     @node-click="handleNodeClick"
                     :default-expanded-keys="expanded"
                     :filter-node-method="filterNode"
                     :expand-on-click-node="false"
                     highlight-current>
                <template slot-scope="{ node, data }">
                    <span :class="data.type == 1 ? 'el-icon-office-building' : 'el-icon-s-flag'">
                        {{ data.name }}({{ data.subNodes ? data.subNodes.length : 0 }})
                    </span>
                </template>
            </el-tree>
        </el-aside>
        <el-container>
            <el-header style="height: 50px;line-height: 45px;padding: 0;">
                <el-button type="primary" size="small" @click="handleSave" icon="el-icon-document-add">保 存</el-button>
                <el-button type="primary" size="small" @click="handleAddFirst" icon="el-icon-plus">添加顶级</el-button>
                <el-button v-show="nextShow" type="primary" size="small" @click="handleNext" icon="el-icon-plus">添加下级
                </el-button>
                <el-popconfirm style="margin-left:9px;" v-show="nextShow" title="删除当前节点和所有孙节点，数据不可恢复，请确认是否删除"
                               @confirm="handleDel">
                    <el-button slot="reference" type="danger" size="small" icon="el-icon-delete">删除当前组织和所有子孙节点
                    </el-button>
                </el-popconfirm>
            </el-header>
            <el-main style="padding-right:5px;">
                <el-form ref="form" :model="form" :rules="rules" class="x-edit" style="white-space: normal">
                    <el-form-item label="机构编码">
                        <el-input disabled v-model="form.organCode" size="small"/>
                    </el-form-item>
<!--                    <el-form-item label="部门编码">
                        <el-input disabled v-model="form.deptCode" size="small"/>
                    </el-form-item>-->
                    <el-form-item label="名称" prop="name">
                        <el-input v-model="form.name" size="small" maxlength="30" show-word-limit/>
                    </el-form-item>
                    <el-form-item label="类型" prop="type">
                        <x-radio-group :disabled="form.id !== ''" v-model="form.type" size="small" dictType="T_ORGAN"
                                       @change="changeType"/>
                    </el-form-item>
                    <el-form-item label="行政区划" prop="areaCode">
                        <city-picker v-model="form.areaCode" size="small" style="width:100%"></city-picker>
                    </el-form-item>
<!--                    <el-form-item label="组织等级" prop="organLevel">
                        <x-selector-one v-model="form.organLevel" size="small" dictType="T_ORGAN_LEVEL"/>
                    </el-form-item>-->
                    <el-form-item label="统一社会信用代码" prop="socialCreditCode">
                        <el-input v-model="form.socialCreditCode" size="small" maxlength="18" show-word-limit
                                  :disabled="typeDept === form.type"/>
                    </el-form-item>
<!--                    <el-form-item label="数据密级" prop="classification">
                        <x-selector-one v-model="form.classification" size="small"
                                        dictType="T_SECURITY_CLASSIFICATION"/>
                    </el-form-item>-->
<!--                    <el-form-item label="树结构层级" prop="level">
                        <el-input disabled v-model="form.level" size="small"/>
                    </el-form-item>-->
                    <el-form-item label="同级排序" prop="sort">
                        <el-input-number v-model.number="form.sort" size="small" :min=0 :max=1000
                                         :step=1></el-input-number>
                    </el-form-item>
                    <el-form-item label="描述" prop="description" class="line">
                        <el-input type="textarea" v-model="form.description" size="small" maxlength="150"
                                  show-word-limit/>
                    </el-form-item>
                </el-form>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import CityPicker from '@/components/CityPicker';
import {ruleBuilder} from '@/util/validate';
import * as service from '@/service/system/organ';
import XTreeBase from "@/components/x/XTreeBase";
import {DICT_TYPE_ORGAN} from "@/util/constant";
import {deepCopy} from "@/util/objects";

export default {
    name: "Organ",
    mixins: [XTreeBase],
    components: {CityPicker},
    beforeCreate() {
        this.typeDept = DICT_TYPE_ORGAN.DEPT.key;
        this.treeService = service.mainTree;
        this.addService = service.add;
        this.updateService = service.update;
        this.delService = service.del;
        let {required} = ruleBuilder;
        this.rules = {
            name: [required()],
            level: [required()],
            socialCreditCode: [required('string', ['change', 'blur'])],
            type: [required('string', ['change', 'blur'])],
            organLevel: [required('string', ['change', 'blur'])],
            areaCode: [required('string', ['change', 'blur'])]
        };
        this.defaultModel = {
            id: "",
            parentId: "0",
            name: "",
            level: 1,
            sort: 0,
            socialCreditCode: "",
            type: "1",
            organLevel: "",
            organCode: "",
            deptCode: "",
            areaCode: "",
            description: "",
            classification: "0"
        }
    },
    data() {
        return {
            parentSocialCreditCode: "",
            form: deepCopy(this.defaultModel)
        };
    },
    methods: {
        handleRefreshTree() {
            this.defaultRefreshTree();
        },
        handleNodeClick(data) {
            this.defaultNodeClick(data);
        },
        handleSave() {
            this.defaultSave().then((response) => {
                this.handleRefreshTree();
            })
        },
        handleAddFirst() {
            this.defaultToAdd();
        },
        handleNext() {
            let parentId = this.form.id;
            let level = this.form.level + 1;
            this.parentSocialCreditCode = this.form.socialCreditCode;
            this.defaultToAdd();
            this.form.parentId = parentId;
            this.form.level = level;
        },
        handleDel() {
            this.defaultDel().then((response) => {
                this.handleRefreshTree();
                this.defaultToAdd();
            })
        },
        changeType() {
            if (this.form.type === this.typeDept) {
                this.form.socialCreditCode = this.parentSocialCreditCode;
            } else {
                this.form.socialCreditCode = "";
            }
        }
    }
};
</script>

<style scoped>

</style>
